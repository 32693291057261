import React from 'react'
import Header from '../pageComponents/common/Header'
import HeroSection from '../pageComponents/HomeComponents/Hero'
import TrustedPartners from '../pageComponents/HomeComponents/TrustedPartners'
import AboutSection from '../pageComponents/HomeComponents/AboutSection'
import SweetBenefits from '../pageComponents/HomeComponents/SwiftBenefit'
import WhatWeDo from '../pageComponents/HomeComponents/WhatWeDo'
import EmpowringSection from '../pageComponents/HomeComponents/Empowering'
import Footer from '../pageComponents/common/Footer'
import CoreValues from '../pageComponents/HomeComponents/CoreValue'
import CTASection from '../pageComponents/common/CTA'
import FAQSection from '../pageComponents/HomeComponents/FaqSection'
import Testimonials from '../pageComponents/HomeComponents/Testimonials'
import ContactSection from '../pageComponents/common/ContactSection'

const Home = () => {
  return (
    <div className='w-full h-full'>
        <Header />
        <div className="w-full h-full gap-8 flex flex-col flex-grow items-center pb-20 max-md:py-5">
            <HeroSection />
            <TrustedPartners />
            <AboutSection />
            <SweetBenefits />
            <WhatWeDo />
            <EmpowringSection />
            <CoreValues />
            <Testimonials />
            <CTASection />
            <ContactSection />
            <FAQSection />
        </div>
        <Footer />
    </div>
  )
}

export default Home